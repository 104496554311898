import {graphql, useStaticQuery} from "gatsby";
import * as React from "react";
import {Breadcrumb} from "../../components/Breadcrumb";
import {Period} from "../../components/Period";
import {Seo} from "../../components/Seo";
import {Anchor} from "../../types";
// @ts-ignore
import * as styles from "./index.module.css";

interface Job {
  title: string;
  company: string;
  dates: string;
  responsibilities: string[];
}

interface Project {
  name: string;
  anchor: Anchor;
  features: string[];
}

interface Skill {
  label: string;
  skills: string[];
}

const experience: Job[] = [
  {
    title: "Software Engineer",
    company: "Google",
    dates: "July 2021",
    responsibilities: [],
  },
  {
    title: "Software Engineer",
    company: "Amazon Web Services",
    dates: "July 2020 - July 2021",
    responsibilities: [
      "Worked with multiple AWS Cloud technologies to build serverless applications to save over $1.5M annually compared to traditional servers.",
      "Converted expensive Rest APIs to GraphQL to save hours of computational and bandwidth consumption.",
      "Built microservices with over 90% test coverage to scale for millions of customers in different regions with an 181ms average request time.",
      "Contributed to building responsive and accessible Micro Frontends with React to speed up deployments without affecting the workflow of other teams working on the same application.",
      "Wrote multiple technical documents for software documentation, feature planning, threat models, operational excellence, and correction of errors.",
      "Added detailed logging to back-end and front-end to gather metrics on how customers use our sites to inform future product decisions.",
    ],
  },
  {
    title: "Software Engineer",
    company: "Air Care Companies",
    dates: "April 2019 – October 2019",
    responsibilities: [
      "Redesigned software architecture for over an overall 80% speed increase.",
      "Wrote and managed multiple REST APIs to make extendable services.",
      "Managed multiple back-ends and databases with PHP, SQL, and MSSQL.",
      "Worked efficiently in an Agile team.",
      "Built front-end pages in HTML5, CSS, and JavaScript.",
      "Prototyped a mobile app with C#, Xamarin, and Java.",
    ],
  },
  {
    title: "Software Engineer",
    company: "Hamilton Multimedia LLC",
    dates: "March 2017 – March 2020",
    responsibilities: [
      "Designed websites with an Agile methodology.",
      "Built sites using Bootstrap and JavaScript for a responsive experience.",
      "Followed secure and best practices to increase website and database integrity.",
      "Debugged sites to find potential bugs to increase customer satisfaction and business.",
      "Built front-end pages in HTML5, CSS, and JavaScript.",
      "Handled databases and back-end services with PHP and MYSQL.",
    ],
  },
];

const projects: Project[] = [
  {
    name: "AWS Internal Sites",
    anchor: {
      label: "https://aws.amazon.com",
      url: "https://aws.amazon.com",
    },
    features: [
      "Internal sites that transfers terabytes of data and scales to millions of users.",
      "Has an 90% uptime with accessibility and i18n to remove usability barriers.",
      "Covered with unit tests, integration tests, and Chaos engineering.",
    ],
  },
  {
    name: "Google Play App Back-end",
    anchor: {
      label: "https://play.google.com/store",
      url: "https://play.google.com/store",
    },
    features: [],
  },
];

const openSourceProjects: Project[] = [
  {
    name: "Personal Site / Blog",
    anchor: {
      label: "https://www.gregorygaines.com",
      url: "https://www.gregorygaines.com",
      noRel: false,
    },
    features: [
      "Created React, Google Cloud, TailwindCSS, GraphCMS, and Gatsby to showcase my experience and technical write-ups about programming.",
    ],
  },
  {
    name: "Gameboy Emulator",
    anchor: {
      label: "https://github.com/gregorygaines/gBoy",
      url: "https://github.com/gregorygaines/gBoy",
      noRel: true,
    },
    features: [
      "A reverse engineer of the Gameboy hardware written in Java.",
      "Emulates the Z80 CPU instruction set, LCD, and other subsystems.",
      "Able to run Gameboy software on any platform that can run Java.",
    ],
  },
  {
    name: "Unbeatable Tic-Tac-Toe AI",
    anchor: {
      label: "https://www.tictactoe.gregei.com",
      url: "https://www.tictactoe.gregei.com",
      noRel: false,
    },
    features: [
      "Unbeatable Tic-Tac-Toe AI written in JavaScript that uses a Decision tree to remain unbeatable.",
    ],
  },
];

const skills: Skill[] = [
  {
    label: "Programming Languages",
    skills: [
      "Java",
      "C++",
      "C#",
      "SQL",
      "MSSQL",
      "HTML",
      "CSS",
      "SASS / SCSS",
      "Golang",
      "PHP",
      "JavaScript",
      "TypeScript",
    ],
  },
  {
    label: "Front-end",
    skills: [
      "Next.js",
      "Babel",
      "Strapi",
      "React",
      "jQuery",
      "Bootstrap",
      "TailwindCSS",
      "Redux",
      "Webpack",
      "ESLint",
      "Prettier",
      "Styled Components",
      "Husky",
      "JavaFX",
      "Java Swing",
    ],
  },
  {
    label: "Back-end",
    skills: [
      "Express.js",
      "Spring",
      "Micronaut",
      "Node.js",
      "Redis",
      "Nginx",
      "Apache",
      "Codeigniter",
      "Laravel",
      "GraphQL",
      "MongoDB",
      "MySQL",
      "AWS",
      "Google Cloud",
      "PostgreSQL",
    ],
  },
  {
    label: "Tools & Methodologies",
    skills: [
      "Java",
      "C++",
      "C#",
      "SQL",
      "MSSQL",
      "HTML",
      "CSS",
      "SASS / SCSS",
      "Golang",
      "PHP",
      "JavaScript",
      "TypeScript",
    ],
  },
];

const Resume = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          email
          github
          linkedin
        }
      }
    }
  `);

  return (
    <>
      <Seo title="Resume" description="Here is Gregory Gaines's work experience and projects." />
      <Breadcrumb activeLabel="Resume" />
      <article className="container mx-auto max-w-4xl my-20 px-4 py-5 md:py-10 md:px-10 lg:shadow-xl rounded group dark:bg-gray-900 dark:text-gray-300 shadow-xl dark:shadow-none">
        {/* Put downlaod button in corner as black with printer icon */}
        <div className="relative">
          <a
            aria-label="Resume download"
            href="https://media.graphcms.com/voQCc64TXiJM43wxpuig"
            target="_blank"
            rel="noreferrer"
            className="rounded-full font-mono rounded p-2 bg-blue-600 mb-2 absolute right-0 shadow-md hover:bg-blue-600 focus:ring-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-50 items-center"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </div>

        <h2 className="text-center font-bold text-5xl mb-10">Gregory Gaines</h2>

        <div className={styles.resume}>
          <ul className="mb-5">
            <li className="mb-1">
              <span className="font-bold">Email</span>:{" "}
              <span className="underline-decoration-blue">
                {data.site.siteMetadata.email}
              </span>
            </li>
            <li className="mb-1">
              <span className="font-bold">LinkedIn</span>:{" "}
              <a
                className="underline-decoration-blue"
                href={data.site.siteMetadata.linkedin}
                target="_blank"
                rel="noreferrer"
              >
                {data.site.siteMetadata.linkedin}
              </a>
            </li>
            <li className="mb-1">
              <span className="font-bold">GitHub</span>:{" "}
              <a
                className="underline-decoration-blue"
                href={data.site.siteMetadata.github}
                target="_blank"
                rel="noreferrer"
              >
                {data.site.siteMetadata.github}
              </a>
            </li>
          </ul>

          <section>
            <h3 className="font-bold border-b pb-1 mb-3">
              Experience
              <Period />
            </h3>
            {experience.map((experience) => {
              return (
                <div className="mb-5" key={experience.company}>
                  <div className="flex justify-between mb-2">
                    <span className="font-bold">{experience.title}</span>
                    <span className="font-bold">{experience.company}</span>
                    <span className="font-bold">{experience.dates}</span>
                  </div>
                  <ul className="list-disc list-inside">
                    {experience.responsibilities.map((experience) => {
                      return (
                        <li className="mb-2" key={experience}>
                          {experience}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </section>

          <section>
            <h3 className="font-bold border-b pb-1 mb-3">
              Professional Projects
              <Period />
            </h3>
            {projects.map((project) => {
              return (
                <div className="mb-5" key={project.name}>
                  <div className="flex justify-between mb-2">
                    <span className="font-bold">
                      {project.name} -{" "}
                      <a href={project.anchor.url} rel="noreferrer">
                        {project.anchor.label}
                      </a>
                    </span>
                  </div>
                  <ul className="list-disc list-inside text-md">
                    {project.features.map((feature) => {
                      return (
                        <li className="mb-2" key={feature}>
                          {feature}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </section>

          <section>
            <h3 className="font-bold border-b pb-1 mb-3">
              Open Source Projects
              <Period />
            </h3>
            {openSourceProjects.map((openSourceProject) => {
              return (
                <div className="mb-5" key={openSourceProject.name}>
                  <div className="flex justify-between mb-2">
                    <span className="font-bold">
                      {openSourceProject.name} -{" "}
                      <a
                        href={openSourceProject.anchor.url}
                        rel={
                          openSourceProject.anchor.noRel
                            ? "noreferrer"
                            : undefined
                        }
                      >
                        {openSourceProject.anchor.label}
                      </a>
                    </span>
                  </div>
                  <ul className="list-disc list-inside">
                    {openSourceProject.features.map((feature) => {
                      return (
                        <li className="mb-2" key={feature}>
                          {feature}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </section>

          <section>
            <h3 className="font-bold border-b pb-1 mb-3">
              Skills
              <Period />
            </h3>
            {skills.map((skill) => {
              return (
                <div className="mb-5" key={skill.label}>
                  <div className="flex flex-row mb-2 flex-wrap gap-x-1">
                    <span className="font-bold">{skill.label}:</span>
                    {skill.skills.map((skill) => {
                      return <span key={skill}>{skill};</span>;
                    })}
                  </div>
                </div>
              );
            })}
          </section>
        </div>
      </article>
    </>
  );
};

export default Resume;
